<template>
  <article class="mb-20">
    <section class="mb-10">
      <transition name="slide-fade" mode="out-in">
        <form @submit.prevent="submit" v-if="allowProcessor && canAssign">
          <div class="flex items-center flex-wrap justify-end">
            <button
              type="button"
              class="btn btn-blue mb-2 mr-2"
              :disabled="form.loading"
              @click="openMembersModal"
            >
              <ion-icon name="person-add" class="text-base mr-2" />
              Add New Member
            </button>

            <custom-select
              v-model="form.data.admin_id.value"
              :options="assignableAdminOptions"
              placeholder="Select to assign"
              class="processor-select-input mb-2 mr-2"
              :disabled="
                !assignableAdminOptions || !assignableAdminOptions.length
              "
            >
              <template #placeholder>
                <div class="flex items-center">
                  <span class="mr-2">Select to assign</span>
                  <ion-icon name="chevron-down-outline" />
                </div>
              </template>
            </custom-select>

            <button
              type="submit"
              class="btn btn-blue mb-2 mr-2"
              :disabled="!canSubmit || form.loading"
            >
              <ion-icon name="people" class="text-base mr-2" />
              <sm-loader class="sm-loader-white" v-if="form.loading" />
              <span v-else>Assign</span>
            </button>

            <button
              type="button"
              class="btn btn-red-soft mb-2"
              :disabled="form.loading"
              @click="stopProcessor"
            >
              <ion-icon name="power" class="text-base" />
            </button>
          </div>
          <div class="w-full md:w-6/10 lg:w-5/10 xl:w-4/10 ml-auto">
            <div class="alert alert-red" v-if="getFormError(form)">
              <span class="alert-icon">
                <ion-icon name="alert-outline" />
              </span>
              <span v-html="getFormError(form)" />
            </div>
            <div class="alert alert-green" v-else-if="form.success">
              <span class="alert-icon">
                <ion-icon name="alert-outline" />
              </span>
              <span>
                User(s) assigned to admin successfully!
              </span>
            </div>
          </div>
        </form>
        <div class="text-right" v-else>
          <div class="flex flex-wrap items-center">
            <div class="flex items-center flex-wrap mr-3">
              <h2 class="text-2xl font-bold mr-3">All users</h2>
              <router-link
                :to="{ name: 'users-new' }"
                class="text-sm text-gray-700"
              >
                + Add new user
              </router-link>
            </div>
            <div class="ml-auto" v-if="canAssign">
              <button
                type="submit"
                class="btn btn-blue mb-2"
                @click="startProcessor"
              >
                <ion-icon name="power" class="text-base mr-2" />
                <span>Credpal Kazuma</span>
              </button>
            </div>
          </div>
        </div>
      </transition>
    </section>

    <section
      class="border border-blue-200 duration-300 flex"
      :class="{ 'border-blue-500 shadow-2xl': allowProcessor }"
    >
      <div class="duration-300" :class="{ 'w-full': !isOpen, 'w-1/2': isOpen }">
        <datatable
          :url="users.url"
          :ajax="true"
          :ajaxPagination="true"
          :columns="users.columns"
          :filters="users.filters"
          :query="users.query"
          :className="tableRowClassName"
          :fillable="true"
          :selectable="allowProcessor"
          :dropdown="false"
          :on-click="click"
          sort="created_at"
          order="desc"
          ref="table"
          @selection-update="data => selectionUpdate(data, form)"
        >
          <template #header>
            <div class="pt-4">
              <search-form
                placeholder="Search users names, emails, phone numbers, bvn, etc."
                class="mb-5"
                v-model="users.query"
                @submit="reloadTable"
              />
              <div class="flex items-start flex-wrap xl:flex-no-wrap">
                <div class="flex flex-wrap items-center w-full">
                  <span class="processor-filter-title">Filters:</span>
                  <template v-for="(filter, index) in users.filters">
                    <span
                      v-html="filter.title"
                      :key="index"
                      class="processor-filter-item"
                      :class="{ active: currentFilter === filter }"
                      @click.prevent="toggleFilter(filter)"
                    />
                  </template>
                </div>
                <!-- <div class="flex flex-wrap items-center xl:ml-auto">
                  <span class="processor-filter-title">Exports:</span>
                  <button class="btn btn-purple-outline btn-sm mb-2 mr-2" @click.prevent="$refs.table.exportAll()">
                    <ion-icon name="cloud-download-outline" class="text-base" />
                    <span class="ml-2">Export All Results</span>
                  </button>
                  <button class="btn btn-blue-outline btn-sm mb-2" @click.prevent="$refs.table.exportData()">
                    <ion-icon name="cloud-download-outline" class="text-base" />
                    <span class="ml-2">Export Filtered Results</span>
                  </button>
                </div> -->
              </div>
            </div>
          </template>
        </datatable>
      </div>

      <div class="w-1/2 px-8 py-10 border-l border-blue-200" v-if="isOpen">
        <div class="h-full overflow-y-auto">
          <div class="animated animation-delay-300 fadeIn">
            <div class="mb-5">
              <div class="flex flex-row items-center justify-between mb-1">
                <div class="flex flex-row items-center">
                  <h2 class="text-lg font-bold mr-2">
                    {{ users.selected.name | sentenceCase }}
                    {{ users.selected.last_name | sentenceCase }}
                  </h2>
                </div>
                <div class="dropdown ml-auto">
                  <button
                    type="button"
                    class="flex flex-col focus:outline-none"
                    dropdown
                  >
                    <div class="h-1 w-1 bg-gray-400 mb-1 rounded-full"></div>
                    <div class="h-1 w-1 bg-gray-400 mb-1 rounded-full"></div>
                    <div class="h-1 w-1 bg-gray-400 rounded-full"></div>
                  </button>
                  <ul class="dropdown-menu">
                    <a
                      href="#!"
                      class="dropdown-menu-item text-red-500"
                      @click.prevent="confirmDelete"
                      >Delete</a
                    >
                  </ul>
                </div>
              </div>
              <div class="font-hairline text-xs text-gray-500">
                {{ position | sentenceCase }}
              </div>
              <router-link
                :to="{
                  name: 'staff-view',
                  params: { staffId: users.selected.id }
                }"
                class="inline-flex items-center text-sm text-blue-500"
              >
                <span>View Profile</span>
              </router-link>
            </div>

            <transition name="fade" mode="out-in">
              <div>
                <!-- Profile Not Completed -->
                <div class="mb-10" v-if="!userProfileCompleted">
                  <div class="alert alert-blue-soft mb-5">
                    <span class="alert-icon">!</span>
                    <span
                      >{{ users.selected.name }} hasn't completed their
                      profile.</span
                    >
                  </div>
                </div>
                <!-- Profile Not Completed -->

                <!-- Profile Completed -->
                <template v-else>
                  <div class="text-xs">
                    <template v-if="userApprovalPending">
                      <div class="alert alert-blue-soft mb-5">
                        <span class="alert-icon">!</span>
                        <span
                          >{{ users.selected.name }}'s profile needs
                          activation.</span
                        >
                      </div>
                    </template>
                  </div>
                </template>
                <!-- Profile Completed -->
              </div>
            </transition>

            <div
              class="grid grid-cols-1 xl:grid-cols-2 border-blue-200 border mb-8"
            >
              <div class="col-span-1">
                <div class="px-8 xl:px-12 py-8 border-blue-200 relative">
                  <!-- <span class="absolute h-4 bg-green-500 block left-0 ml-3 xl:ml-5" style="width: 3px"></span> -->
                  <div class="flex justify-between items-center mb-4">
                    <div class="text-xs">Salary</div>
                    <div
                      class="flex items-center justify-center w-6 h-6 border-2 border-solid border-green-500 text-green-500 rounded-full font-black"
                    >
                      ₦
                    </div>
                  </div>
                  <div class="text-xs font-medium">
                    ₦
                    {{
                      users.selected.profile
                        ? users.selected.profile.salary
                        : 0 | currency
                    }}
                  </div>
                </div>
              </div>

              <div class="col-span-1">
                <div
                  class="px-8 xl:px-12 py-8 border-t xl:border-t-0 xl:border-l border-blue-200 relative"
                >
                  <!-- <span class="absolute h-4 bg-blue-500 block left-0 ml-3 xl:ml-5" style="width: 3px"></span> -->
                  <div class="flex justify-between items-center mb-4">
                    <div class="text-xs">Position</div>
                    <div
                      class="w-6 h-6 flex items-center justify-center border border-solid border-blue-500 text-blue-500 rounded-full font-black text-sm"
                    >
                      <ion-icon name="briefcase-outline"></ion-icon>
                    </div>
                  </div>
                  <div class="text-xs font-medium">
                    {{
                      users.selected.profile
                        ? users.selected.profile.position
                        : '---'
                    }}
                  </div>
                </div>
              </div>

              <div class="col-span-1">
                <div
                  class="px-8 xl:px-12 py-8 border-t border-blue-200 relative"
                >
                  <!-- <span class="absolute h-4 bg-blue-500 block left-0 ml-3 xl:ml-5" style="width: 3px"></span> -->
                  <div class="flex justify-between items-center mb-4">
                    <div class="text-xs">Phone Number</div>
                    <div
                      class="w-6 h-6 flex items-center justify-center border border-solid border-blue-500 text-blue-500 rounded-full font-black text-sm"
                    >
                      <ion-icon name="call-outline"></ion-icon>
                    </div>
                  </div>
                  <div class="text-xs font-medium">
                    {{ users.selected.phone_no }}
                  </div>
                </div>
              </div>

              <div class="col-span-1">
                <div
                  class="px-8 xl:px-12 py-8 border-t xl:border-l border-blue-200 relative"
                >
                  <!-- <span class="absolute h-4 bg-blue-500 block left-0 ml-3 xl:ml-5" style="width: 3px"></span> -->
                  <div class="flex justify-between items-center mb-4">
                    <div class="text-xs">Email</div>
                    <div
                      class="w-6 h-6 flex items-center justify-center border border-solid border-blue-500 text-blue-500 rounded-full font-black text-sm"
                    >
                      <ion-icon name="mail-outline"></ion-icon>
                    </div>
                  </div>
                  <div class="text-xs font-medium">
                    {{ users.selected.email }}
                  </div>
                </div>
              </div>

              <div class="col-span-1">
                <div
                  class="px-8 xl:px-12 py-8 border-t border-blue-200 relative"
                >
                  <!-- <span class="absolute h-4 bg-blue-500 block left-0 ml-3 xl:ml-5" style="width: 3px"></span> -->
                  <div class="flex justify-between items-center mb-4">
                    <div class="text-xs">Employment Type</div>
                    <div
                      class="w-6 h-6 flex items-center justify-center border border-solid border-blue-500 text-blue-500 rounded-full font-black text-sm"
                    >
                      <ion-icon name="briefcase-outline"></ion-icon>
                    </div>
                  </div>
                  <div class="text-xs font-medium">
                    {{
                      users.selected.profile
                        ? users.selected.profile.emp_type
                        : '---'
                    }}
                  </div>
                </div>
              </div>

              <div class="col-span-1">
                <div
                  class="px-8 xl:px-12 py-8 border-t xl:border-l border-blue-200 relative"
                >
                  <!-- <span class="absolute h-4 bg-blue-500 block left-0 ml-3 xl:ml-5" style="width: 3px"></span> -->
                  <div class="flex justify-between items-center mb-4">
                    <div class="text-xs">Loan Facility</div>
                    <div
                      class="w-6 h-6 flex items-center justify-center border border-solid border-blue-500 text-blue-500 rounded-full font-black text-sm"
                    >
                      <ion-icon name="calculator-outline"></ion-icon>
                    </div>
                  </div>
                  <div class="text-xs font-medium">
                    {{
                      users.selected.profile
                        ? users.selected.profile.current_loan_facility
                        : '---'
                    }}
                  </div>
                </div>
              </div>

              <div class="col-span-1">
                <div
                  class="px-8 xl:px-12 py-8 border-t border-blue-200 relative"
                >
                  <!-- <span class="absolute h-4 bg-green-500 block left-0 ml-3 xl:ml-5" style="width: 3px"></span> -->
                  <div class="flex justify-between items-center mb-4">
                    <div class="text-xs">Total Loan Amount</div>
                    <div
                      class="flex items-center justify-center w-6 h-6 border-2 border-solid border-green-500 text-green-500 rounded-full font-black"
                    >
                      ₦
                    </div>
                  </div>
                  <div class="text-xs font-medium">₦ 0.00</div>
                </div>
              </div>

              <div class="col-span-1">
                <div
                  class="px-8 xl:px-12 py-8 border-t xl:border-l border-blue-200 relative"
                >
                  <!-- <span class="absolute h-4 bg-orange-500 block left-0 ml-3 xl:ml-5" style="width: 3px"></span> -->
                  <div class="flex justify-between items-center mb-4">
                    <div class="text-xs">Current Loan</div>
                    <div
                      class="flex items-center justify-center w-6 h-6 border-2 border-solid border-green-500 text-green-500 rounded-full font-black"
                    >
                      ₦
                    </div>
                  </div>
                  <div class="text-xs font-medium">₦ 0.00</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <modal className="w-full md:w-8/10 xl:w-6/10" ref="membersModal">
      <h6 class="text-lg mb-10">Manage Sales Team Admins</h6>
      <div class="flex flex-wrap md:flex-no-wrap">
        <div class="border-blue-200 w-full mb-4 md:mr-4 md:mb-0">
          <datatable
            :ajax="true"
            :ajax-pagination="true"
            :url="`${this.$baseurl}/admins`"
            :columns="admins.columns"
            :selectable="true"
            :header="false"
            :fillable="false"
            class="mb-5"
            ref="adminsTable"
            @selection-update="data => selectionUpdate(data, additionForm)"
          />
          <button
            class="btn btn-blue"
            :disabled="additionForm.loading || !canAdd"
            @click="add"
          >
            <ion-icon name="add-circle" class="text-base mr-2" />
            <sm-loader class="sm-loader-white" v-if="additionForm.loading" />
            <span v-else>Add</span>
          </button>
        </div>
        <div class="border-blue-200 w-full">
          <datatable
            :ajax="true"
            :ajax-pagination="true"
            :url="`${this.$baseurl}/admin/personal/assignments/admins/sales`"
            :columns="assignableAdmins.columns"
            :selectable="true"
            :header="false"
            :fillable="false"
            class="mb-5"
            ref="assignableAdminsTable"
            @selection-update="data => selectionUpdate(data, removalForm)"
          />
          <button
            class="btn btn-red"
            :disabled="removalForm.loading || !canRemove"
            @click="remove"
          >
            <ion-icon name="remove-circle" class="text-base mr-2" />
            <sm-loader class="sm-loader-white" v-if="removalForm.loading" />
            <span v-else>Remove</span>
          </button>
        </div>
      </div>
    </modal>

    <modals-user
      :selected-user="users.selected"
      ref="userModal"
      @delete="userDeleted"
      @close="userModalClosed"
    />
  </article>
</template>

<script>
export default {
  data() {
    return {
      allowProcessor: false,
      currentFilter: null,
      additionForm: this.$options.basicForm([{ name: 'admins', value: [] }]),
      removalForm: this.$options.basicForm([{ name: 'admins', value: [] }]),
      form: this.$options.basicForm([{ name: 'users', value: [] }, 'admin_id']),
      admins: this.$options.resource([], {
        columns: [
          {
            name: 'name',
            th: 'CredPal Admin',
            className: 'w-full',
            render: admin => `${admin.name} ${admin.last_name}`
          }
        ]
      }),
      assignableAdmins: this.$options.resource([], {
        columns: [
          {
            name: 'name',
            th: 'Assignable Admin',
            className: 'w-full',
            render: admin => `${admin.name} ${admin.last_name}`
          }
        ]
      }),
      users: this.$options.resource([], {
        selected: null,
        query: '',
        url: `${this.$baseurl}/admin/personal/users`,
        columns: [
          {
            name: 'id',
            th: 'User ID'
          },
          {
            name: 'name',
            th: 'Name',
            render: user => user.name + ' ' + user.last_name
          },
          {
            name: 'company',
            th: 'Company',
            render: (user, company) => company?.name || '----'
          },
          {
            name: 'email',
            th: 'Email Address'
          },
          {
            name: 'progress',
            th: 'Progress',
            render: user => {
              return this.progressIcons(user);
            },
            sortable: false
          },
          {
            name: 'pnd',
            th: 'Post No Debit',
            render: user => {
              if (user.pnd == 1) {
                return `<div class="badge badge-orange-soft-outline">Active</div>`;
              }
              return `<div class="badge badge-blue-soft-outline">Inactive</div>`;
            }
          },
          {
            name: 'source',
            th: 'Source',
            render: user => {
              return user.source || 'N/A';
            }
          },
          {
            name: 'status',
            th: 'Status',
            render: (user, status) => {
              return this.statusBadge(status, user);
            }
          },
          {
            name: 'admins',
            th: 'Assigned To',
            render: user => {
              return this.assignedAdmin(user, 'sales');
            },
            sortable: false
          },
          {
            name: 'created_at',
            th: 'Date Registered',
            render: (user, created_at) => {
              return `
                <span class="opacity-75">${this.$moment(created_at).format(
                  'ddd, D MMM, YYYY'
                )}</span>
                <span class="font-medium">(${this.$moment(
                  created_at
                ).fromNow()})</span>
              `;
            }
          }
        ],
        filters: [
          { title: 'pre-approved', name: 'pre-approved' },
          { title: 'approved', name: 'approved' },

          { title: 'unassigned', name: 'unassigned' },
          { title: 'assigned', name: 'assigned' },
          { title: 'assigned to me', name: 'assigned_to_me' },
          { title: 'completed application', name: 'processing' },
          { title: 'incompleted application', name: 'incomplete' },
          { title: 'web', name: 'web' },
          { title: 'mobile', name: 'mobile' },
          { title: 'mobile', name: 'mobile' },
          { title: 'merchant', name: 'merchant' }
        ]
      })
    };
  },
  computed: {
    assignableAdminOptions() {
      return this.$refs.assignableAdminsTable.ajaxItems?.map(admin => ({
        title: `${admin.name} ${admin.last_name}`,
        value: admin.id
      }));
    },
    canAdd() {
      return this.additionForm.data.admins.value?.length;
    },
    canAssign() {
      return this.canDo('credpal:can_create_assignments');
    },
    canRemove() {
      return this.removalForm.data.admins.value?.length;
    },
    canSubmit() {
      return (
        this.form.data.admin_id.value && this.form.data.users.value?.length
      );
    },
    isOpen() {
      return this.users.selected && false;
    }
  },
  methods: {
    click(user, value, name, index) {
      if (this.allowProcessor) {
        return this.$refs.table.selectItemByIndex(index);
      }
      // this.users.selected = this.users.selected === user ? null : user;
      // this.$refs.table.renderData();
      this.users.selected = user;
      this.$refs.userModal.open();
    },
    openMembersModal() {
      this.$refs.membersModal.open();
    },
    reloadTable() {
      this.$refs.table.loadAjaxData();
    },
    startProcessor() {
      this.allowProcessor = true;
    },
    stopProcessor() {
      this.$refs.table.clearSelection();
      this.allowProcessor = false;
    },
    selectionUpdate({ selection }, form) {
      if (form === this.form) {
        form.data.users.value = selection.map(item => item.row?.id);
      } else {
        form.data.admins.value = selection.map(item => item.row?.id);
      }
    },
    tableRowClassName(user, index, row) {
      return row.selected || user === this.users.selected ? 'selected' : '';
    },
    toggleFilter(filter) {
      if (this.currentFilter === filter) {
        this.currentFilter = null;
      } else {
        this.currentFilter = filter;
      }

      this.$refs.table.clickedFilter(this.currentFilter);
    },
    userDeleted() {
      this.reloadTable();
    },
    userModalClosed() {
      this.users.selected = null;
    },
    async add() {
      if (!this.validateForm(this.additionForm)) {
        return false;
      }

      const admins = this.additionForm.data.admins.value;

      this.additionForm.error = null;
      this.additionForm.loading = true;
      await this.sendRequest('admin.assignments.addMembers', {
        data: {
          admins,
          type: 'sales'
        },
        success: () => {
          this.getAssignableAdmins();
          this.$refs.adminsTable.clearSelection();
        },
        error: error => {
          this.additionForm.error = error;
        }
      });
      this.additionForm.loading = false;
    },
    async remove() {
      if (!this.validateForm(this.removalForm)) {
        return false;
      }

      const admins = this.removalForm.data.admins.value;

      this.removalForm.error = null;
      this.removalForm.loading = true;
      await this.sendRequest('admin.assignments.removeMembers', {
        data: {
          admins,
          type: 'sales'
        },
        success: () => {
          this.getAssignableAdmins();
          this.$refs.assignableAdminsTable.clearSelection();
        },
        error: error => {
          this.removalForm.error = error;
        }
      });
      this.removalForm.loading = false;
    },
    async getAssignableAdmins() {
      this.$refs.assignableAdminsTable.loadAjaxData();
    },
    async getAdmins() {
      this.$refs.adminsTable.loadAjaxData();
    },
    async submit() {
      if (!this.validateForm(this.form)) {
        return false;
      }

      this.form.error = null;
      this.form.success = null;
      this.form.loading = true;
      await this.sendRequest('admin.assignments.assign', {
        data: this.getFormData(),
        success: () => {
          this.form.success = true;
          this.$refs.table.clearSelection();
          this.reloadTable();

          setTimeout(() => {
            this.form.success = false;
          }, 2000);
        },
        error: error => {
          this.form.error = error;
        }
      });
      this.form.loading = false;
    }
  }
};
</script>
